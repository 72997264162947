import registerComponents from '../../../../maharo';
import {compose} from 'react-apollo';
import {withMobile} from '../../../shared/enhancers/mobile-enhancer';
import {withCurrentUser} from '../../../shared/enhancers/current-user-enhancer';
import {user} from '../../../data/shared/queries';
import withLanding from '../enhancers/landing';
import {hot} from 'react-hot-loader/root';
import LandingMobile from '../containers/landing-mobile';
import resolvers from '../../../data/landing/resolvers';
import {withApolloContext} from '../../../shared/enhancers/graphql-enhancer';
import {withRouter} from '../../../shared/enhancers/router-enhancer';
import {routes} from './routes';
import withErrorBoundary from '../../../shared/enhancers/error-boundary';

const client = algoliasearch(process.env.ALGOLIA_ID, process.env.ALGOLIA_CLIENT_SEARCH_API_KEY);
client.clearCache();
const searchIndex = client.initIndex(process.env.ALGOLIA_SEARCH_INDEX);

registerComponents(
  {
    LandingMobile: compose(
      withApolloContext,
      withMobile(true),
      withRouter(routes, ({route}) => route),
      withCurrentUser(user),
      withLanding,
      withErrorBoundary()
    )(hot(LandingMobile))
  },
  resolvers(searchIndex)
);

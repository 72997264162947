import React from 'react';
import PropTypes from 'prop-types';
import MobileLayout from '../../../shared/library/containers/mobile-layout';
import {compose} from 'react-apollo';
import {withAnalyticsPayload} from '../../../shared/enhancers/analytics-enhancer';
import LandingComponent from './landing-component';

const LandingMobile = ({userId}) => {
  return (
    <div>
      <MobileLayout userId={userId}>
        {() => {
          return [
            <div key={0}>
              <LandingComponent mobile />
            </div>
          ];
        }}
      </MobileLayout>
    </div>
  );
};

LandingMobile.propTypes = {
  userId: PropTypes.string
};

export default compose(
  withAnalyticsPayload({
    'page.name': 'Stackshare Home Mobile',
    path: typeof window !== 'undefined' ? window.location.pathname : null,
    url: typeof window !== 'undefined' ? window.location.href : null,
    referrer: typeof document !== 'undefined' ? document.referrer : null
  })
)(LandingMobile);
